<template>
  <v-form ref="form" v-model="valid">
    <v-card max-height="100vh">
      <Loading :value="loading" />

      <div>

        <v-system-bar dark color="primary">
          <v-icon class="mr-1">mdi-pencil</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('USER_PRODUCT.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('USER_PRODUCT.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon small class="clickable" @click="closeDialog">
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar color="transparent" dense flat height="60px">
          <v-toolbar-title>{{$t('USER_PRODUCT.TITLE')}}</v-toolbar-title>
          <v-spacer />
          <v-btn
            x-small
            fab
            depressed
            color="error"
            class="mr-4"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            rounded
            depressed
            color="success"
            :disabled="!valid"
            @click="apply"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            {{$t('COMMON.APPLY')}}
          </v-btn>
        </v-toolbar>

        <div class="toolbar-separator primary ml-4 mr-4"/>
      </div>

      <div
        ref="content"
        class="pa-6 pt-0 scroll"
        style="max-height: calc(80vh - 61px)"
      >

        <Loading :value="loading" />

        <div class="pa-0 mt-6">
          <p class="primary--text mb-0">{{$t('PRODUCT.TITLE')}}</p>
          <v-row class="mt-0">
            <v-col cols="4" class="pb-0">
              <v-select
              v-model="user_product.product_id"
              :items="productItems"
              item-text="title"
              item-value="id"
              :rules="[$rules.required]"
              dense
              outlined
              />
            </v-col>
          </v-row>
        </div>

        <div class="pa-0 mt-6">
          <p class="primary--text mb-0">{{$t('USER_PRODUCT.LIMIT_USAGE')}}</p>
          <v-row class="mt-0">
            <v-col cols="2" class="pb-0">
              <v-text-field
              v-model="user_product.limit"
              type="number"
              min="0"
              dense
              outlined
              />
            </v-col>
          </v-row>
        </div>

        <div class="pa-0 mt-6">
          <p class="primary--text mb-0">{{$t('USER_PRODUCT.TIME_USAGE')}}</p>
          <v-row class="mt-0">
            <v-col cols="4" class="pb-0">
              <v-menu
                ref="menu_start_date"
                v-model="menu_start_date"
                :close-on-content-click="false"
                transition="none"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="user_product.start_date"
                    :label="$t('INPUT.DATE_START')"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  no-title
                  v-model="user_product.start_date"
                  :min="new Date().toISOString().substr(0, 10)"
                  @change="save_start_date"
                />
              </v-menu>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-menu
                ref="menu_end_date"
                v-model="user_product.menu_end_date"
                :close-on-content-click="false"
                transition="none"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="user_product.end_date"
                    :label="$t('INPUT.DATE_END')"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  no-title
                  v-model="user_product.end_date"
                  :min="user_product.start_date"
                  @change="save_end_date"
                />
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-form>

</template>

<script>

import Loading from '@/components/Loading'

const ONE_YEAR_AFTER = Date.now() + 1000 * 60 * 60 * 24 * 365

export default {
  name:"FormUserCompactProductsForm",
  components: {
    Loading
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    menu_start_date: new Date().toISOString().slice(0,10),
    menu_end_date: undefined,
    productItems: [],
    user_product_default_value: {
      product_id: undefined,
      limit: 0,
      start_date: new Date().toISOString().slice(0,10),
      end_date: new Date(ONE_YEAR_AFTER).toISOString().slice(0,10),
    },
    user_product: {
      product_id: undefined,
      limit: 0,
      start_date: new Date().toISOString().slice(0,10),
      end_date: new Date(ONE_YEAR_AFTER).toISOString().slice(0,10),
    },
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    },
    id: {
      type: [String, undefined],
      default: undefined
    },
  },
  watch: {
    values: {
      handler () {
        this.init()
      },
      deep: true,
    },
    id (n) {
      this.init(n)
    }
  },
  created () {
    this.loadProductItems()
      .then(() => {
        this.init(this.id)
      })
  },
  methods: {

    loadProductItems() {
      this.loading = true
      return this.$services.api_user.product
        .search({
          status: [1],
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.productItems = response.data
          } else {
            this.productItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    init (id) {
      if (id) {
        this.loadUserProduct()
      } else {
        this.resetUserProduct()
      }
    },

    resetUserProduct() {
      this.user_product = this.$clone(this.user_product_default_value)
    },

    removeItem(user_product_id) {
      this.$services.api_user.user_product
        .remove({ where: { id: user_product_id || this.id } })
        .then((response) => {
          this.dialog = false
        })
    },

    apply() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          this.updateUserProduct()
        } else {
          this.createUserProduct()
        }
      }
    },

    createUserProduct() {
      // prepare values
      const values = {
        user_id: this.loaded_values.id,
        product_id: this.user_product.product_id,
        limit: this.user_product.limit,
        start_date: this.user_product.start_date,
        end_date: this.user_product.end_date,
      }
      this.loading = true
      return this.$services.api_user.user_product
        .create({values})
        .then(response => {
          if (response.data) {
            this.$emit('update', response.data.id)
            this.loadUserProduct()
          }
        })
        .catch((response) => {
          this.$store.dispatch('messenger/add', {
            type: 'error',
            code: response.code,
            message: response.message,
            time: 5000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateUserProduct() {
      this.loading = true
      return this.$services.api_user.user_product
        .update({
          values: {
            user_id: this.loaded_values.id,
            product_id: this.user_product.product_id,
            limit: this.user_product.limit,
            start_date: this.user_product.start_date,
            end_date: this.user_product.end_date,
          },
          where: {id: this.id},
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.$emit('update')
            // this.user_product = response.data[0]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadUserProduct() {
      this.loading = true
      return this.$services.api_user.user_product
        .search({
          where: {id: this.id},
          options: {limit: 1}
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.user_product = response.data[0]
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    closeDialog () {
      this.$emit('close')
    },

    save_start_date(date) {
      this.$refs.menu_start_date.save(date)
    },

    save_end_date(date) {
      this.$refs.menu_end_date.save(date)
    },


  }
}
</script>
